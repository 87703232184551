/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import {
  calcSelectedOptionalFields,
  calcTotalMinutesReqired,
  getMinitesRequiredFields,
} from '../../../@interfaces/course-slot-setting';
import useCourse from '../../../api/use-course';
import { useCustomDomainAttachedShop } from '../../../api/use-custom-domain-attached-shop';
import useReservationTable from '../../../api/use-reservation-table';
import { DomainError, validDomain } from '../../../components/DomainError';
import { PageNotFoundInfo } from '../../../components/PageNotFoundInfo';
import { Loading } from '../../../components/Shared';
import {
  Field,
  FieldResponseValue,
} from '../../../core/types/reservation-form-types';
import { SelectedOptionalField } from '../../../core/types/reservation-resource-types';
import useStorage from '../../../hooks/use-storage';
import { useTrackLocation } from '../../../hooks/use-track-location';
import useUserScript from '../../../hooks/use-user-script';
import { getInflowSource } from '../../../utils/inflow-source';
import { sendShowCourseEventToWidget } from '../../../utils/widget';
import { FormFields } from '../../form/components/ReservationForm/FormFields';
import {
  createDefaultFormResponse,
  getStorageKey,
} from '../../form/routes/FormPage';
import { useConversionFunnelCourse } from '../../insight/hooks/useConversionFunnelCourse';
import { BackButton } from '../components/BackButton';
import { Calendar } from '../components/calendar/Calendar';
import { CourseNameContent } from '../components/CourseNameContent';
import { DescriptionContent } from '../components/DescriptionContent';
import { EmptyReservationTable } from '../components/EmptyReservationTable';
import { HeaderContent } from '../components/HeaderContent';
import { HeaderImageContent } from '../components/HeaderImageContent';
import { InfoMessage } from '../components/InfoMessage';
import { MinuteRequiredInfo } from '../components/MinuteRequiredInfo';
import { ReservationTableContent } from '../components/ReservationTableContent';
import { ResourceForm } from '../components/ResourceForm';
import { useDateRange } from '../hooks/use-date-range';
import { useForceOpenCalendar } from '../hooks/use-force-open-calendar';
import { useNormalizeLocationUrl } from '../hooks/use-normalize-location-url';
import { useResourceResponse } from '../hooks/use-resource-response';
import {
  calcInitialEndDate,
  calcInitialStartDate,
  getPrimaryColor,
  getWeeklyReservationsLength,
  isAnsweredRequiredQuestion,
} from './page-helpers';
import { styles } from './page-styles';

export type PageParams = {
  workspaceUid: string;
  shopUid: string;
  courseUid: string;
};

/**
 * 予約日時選択画面
 * この画面時点で店舗とコースは決定済み
 */
export default function ShopCoursePage() {
  useTrackLocation();
  const location = useLocation();
  const { workspaceUid, shopUid, courseUid } = useParams<PageParams>();
  const validationContext = useForm();
  const { shopSetting, isLoadingCourse, displayMode } = useCourse(
    workspaceUid,
    shopUid,
    courseUid
  );

  useEffect(() => {
    sendShowCourseEventToWidget();
  }, []);

  const searchParams = new URL(window.location.href).searchParams;
  // ウィジェットモード: trueの場合埋め込みに最適化された表示になります。
  const widgetParam = Number(searchParams.get('widget'));
  const widgetMode = widgetParam === 1 ? true : false;
  // ノーマルモード: trueの場合通常の予約表示ルールが適用されます。
  const normalParam = Number(searchParams.get('normal'));
  const normalMode = normalParam === 1 ? true : false;

  // startDateのparamが存在する場合はその値を使用し、なければ店舗設定の値を使用
  const startDateParam = calcInitialStartDate(searchParams, shopSetting);

  const endDateParam = calcInitialEndDate(
    searchParams,
    widgetMode,
    normalMode,
    startDateParam
  );

  // 予約枠を表示する日付範囲とその操作関数
  const {
    dateRange,
    handleChangeCurrentDate,
    handlePrevMonth,
    handleNextMonth,
  } = useDateRange(startDateParam, endDateParam);

  // 所要時間
  const [minutesRequired, setMinutesRequired] = useState<number>();

  // オプショナルフィールドの選択状態
  const [selectedOptionalFields, setSelectedOptionalFields] = useState<
    SelectedOptionalField[]
  >([]);

  // リソースの選択状態
  const { resourceResponse, setResourceResponse, handleChangeMember } =
    useResourceResponse(workspaceUid, courseUid);

  // この画面に必要な基本情報
  const {
    workspaceSetting,
    shop,
    course,
    reservationTable,
    formSetting,
    courseSlotSetting,
    groups,
    isLoadingReservationTable,
  } = useReservationTable(
    workspaceUid,
    shopUid || '',
    courseUid || '',
    dateRange,
    undefined,
    minutesRequired,
    resourceResponse,
    selectedOptionalFields
  );

  // カレンダーを強制的に開く
  const { forceOpenCalendar } = useForceOpenCalendar(
    widgetMode,
    normalMode,
    reservationTable
  );

  //カスタムドメインに関する情報
  const { data: shopCustomDomain, isLoading: isLoadingShopCustomDomain } =
    useCustomDomainAttachedShop(workspaceUid, shopUid);

  // 埋め込みタグを挿入
  useUserScript(workspaceSetting, shopSetting);

  const primaryColor = getPrimaryColor(workspaceSetting, shopSetting);

  // フォームの入力状態(useStorageでローカルストレージから復元)
  // 所要時間の算出に必要;
  const [formResponse, setFormResponse] = useStorage(
    getStorageKey(workspaceUid),
    createDefaultFormResponse(),
    'session'
  );

  useConversionFunnelCourse(
    workspaceUid,
    shopUid,
    getInflowSource(location.search),
    courseUid,
    'dateSelect'
  );

  // 所要時間、リソース、オプショナルフィールドの選択を復元
  // 通常予約モードの場合
  // - オプショナル選択状態に応じて minutesRequired を設定
  // - オプショナル選択肢を設定
  useEffect(() => {
    if (!courseSlotSetting) {
      return;
    }
    const minutesRequired = calcTotalMinutesReqired(
      formResponse,
      courseSlotSetting?.shopCourseSetting
    );
    // コースの所要時間と変更がない場合は強制的な所要時間なしとみなす
    if (
      minutesRequired === courseSlotSetting?.shopCourseSetting?.minutesRequired
    ) {
      setMinutesRequired(undefined);
    } else {
      setMinutesRequired(minutesRequired);
    }
    const newSelectedOptionalFields = calcSelectedOptionalFields(
      formResponse,
      courseSlotSetting?.shopCourseSetting
    );
    // deep equal
    if (
      JSON.stringify(selectedOptionalFields) !==
      JSON.stringify(newSelectedOptionalFields)
    ) {
      setSelectedOptionalFields(newSelectedOptionalFields);
    }
  }, [
    formResponse,
    courseSlotSetting,
    setResourceResponse,
    selectedOptionalFields,
  ]);

  // ノーマライズされた結果の日付範囲にURLを書き換える
  // 初回表示時のみ実行
  useNormalizeLocationUrl(searchParams);

  // オプショナルフィールドの選択状態を変更
  const handleChangeValue = (field: Field, newValues: FieldResponseValue[]) => {
    const { fields } = formResponse;
    const newFieldResponses = [...fields];
    const targetIndex = fields.findIndex((f) => f.uid === field.uid);
    if (targetIndex === -1) {
      newFieldResponses.push({
        uid: field.uid,
        values: newValues,
      });
    } else {
      newFieldResponses[targetIndex].values = newValues;
    }
    setFormResponse({ fields: newFieldResponses });
  };

  // メインコンテンツ
  const content = (): JSX.Element => {
    if (isLoadingShopCustomDomain || isLoadingCourse) {
      return <Loading primaryColor={primaryColor} />;
    }
    if (!shopSetting && !isLoadingCourse) {
      return (
        <PageNotFoundInfo>店舗設定が読み込めませんでした。</PageNotFoundInfo>
      );
    }
    return (
      <div>
        <InfoMessage shopSetting={shopSetting} course={course} />
        <MinuteRequiredInfo
          formResponse={formResponse}
          courseSlotSetting={courseSlotSetting}
        />
        <div>
          <Calendar
            workspaceUid={workspaceUid}
            shopUid={shopUid}
            courseUid={courseUid}
            forceOpen={forceOpenCalendar}
            dateRange={dateRange}
            onChangeCurrentDate={handleChangeCurrentDate}
            primaryColor={primaryColor}
            handlePrevMonth={handlePrevMonth}
            handleNextMonth={handleNextMonth}
            widgetMode={widgetMode}
          />
        </div>
        {reservationTable && !getWeeklyReservationsLength(reservationTable) && (
          <EmptyReservationTable
            widgetMode={widgetMode}
            normalMode={normalMode}
          />
        )}
        {isLoadingReservationTable ? (
          <Loading primaryColor={primaryColor} />
        ) : (
          <ReservationTableContent
            reservationTable={reservationTable}
            dateRange={dateRange}
            primaryColor={primaryColor}
            courseSlotSetting={courseSlotSetting}
            widgetMode={widgetMode}
            displayMode={displayMode}
            directContactInfo={{
              allowLateReservation: shopSetting?.allowLateReservation ?? 'none',
              allowReservationWhenFull:
                shopSetting?.allowReservationWhenFull ?? 'none',
              contactLineId: shopSetting?.contactLineId ?? null,
              contactTelNumber: shopSetting?.contactTelNumber ?? null,
            }}
          />
        )}
      </div>
    );
  };

  // 所要時間が必要なフィールドは表示する
  // その選択状態を使って予約可能な予約枠を表示するため
  const minutesRequiredFields = useMemo(() => {
    return getMinitesRequiredFields(
      formSetting?.fields || [],
      courseSlotSetting?.shopCourseSetting
    );
  }, [formSetting?.fields, courseSlotSetting?.shopCourseSetting]);

  // ドメインチェック
  if (
    !isLoadingReservationTable &&
    !validDomain(workspaceSetting?.domain) &&
    !isLoadingShopCustomDomain &&
    !validDomain(shopCustomDomain?.fqdn ?? null)
  ) {
    return <DomainError domain={workspaceSetting?.domain} />;
  }

  return (
    <div
      css={styles.maxWidth}
      id={widgetMode ? 'widget-mode-shop-course' : undefined}
    >
      <div>
        <HeaderContent
          shop={shop}
          shopSetting={shopSetting}
          workspaceSetting={workspaceSetting}
          primaryColor={primaryColor}
          widgetMode={widgetMode}
        />
        <HeaderImageContent course={course} widgetMode={widgetMode} />
        <BackButton
          workspaceUid={workspaceUid}
          shopUid={shopUid}
          courseSlotSetting={courseSlotSetting}
          widgetMode={widgetMode}
        />
        <CourseNameContent course={course} primaryColor={primaryColor} />
        <DescriptionContent course={course} primaryColor={primaryColor} />
      </div>
      {courseSlotSetting?.resourceConstantSetting?.constants && (
        <div css={styles.minutesRequiredFields}>
          <ResourceForm
            primaryColor={primaryColor}
            constants={courseSlotSetting?.resourceConstantSetting?.constants}
            groups={groups}
            resourceResponse={resourceResponse}
            onChangeMember={handleChangeMember}
          />
        </div>
      )}
      {formSetting && minutesRequiredFields.length > 0 && (
        <div css={styles.minutesRequiredFields}>
          <FormFields
            fields={minutesRequiredFields}
            formResponse={formResponse}
            primaryColor={primaryColor}
            validationContext={validationContext}
            onChangeValue={handleChangeValue}
          />
        </div>
      )}
      {isAnsweredRequiredQuestion(minutesRequiredFields, formResponse) ? (
        content()
      ) : (
        <p
          css={css`
            padding: 0 24px;
            font-size: 14px;
          `}
        >
          項目を選択後、日程選択が可能になります。
        </p>
      )}
      <BackButton
        workspaceUid={workspaceUid}
        shopUid={shopUid}
        courseSlotSetting={courseSlotSetting}
        widgetMode={widgetMode}
      />
    </div>
  );
}
